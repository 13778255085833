import {
  GDSOnSurfaceTokens,
  GDSSurfaceTokens,
} from '@leagueplatform/genesis-core';

// Surface colours affect surfaces of components, such as pages, cards, sheets, and popovers.
const surface: GDSSurfaceTokens = {
  background: {
    primary: '#FFFFFF',
    secondary: '#ECF7FF',
    tertiary: '#F7F7F8',
  },
  card: {
    primary: '#FFFFFF',
    secondary: '#ECF7FF',
    disabled: '#EBEDEF',
  },
};

// On-surface colours are applied to elements that appear on neutral surfaces, typically text, borders, and secondary icons.
const onSurface: GDSOnSurfaceTokens = {
  text: {
    primary: '#2C3135',
    subdued: '#5A656D',
    reversed: '#FFFFFF',
    critical: '#A12127',
    success: '#127D5D',
    warning: '#2C3135',
  },
  border: {
    default: '#7F8B94',
    subdued: '#E0E3E6',
  },
  icon: {
    primary: '#66717A',
  },
};

export const surfaceTokens = {
  surface,
  onSurface,
};
