import { GDSInteractiveTokens } from '@leagueplatform/genesis-core';

// Use interactive colours for interactive components (other than buttons) like links, focus indicators, and selection controls.
// TODO: background and border properties to be removed after the modules are updated: https://everlong.atlassian.net/browse/HMRK-3861
const interactive: GDSInteractiveTokens = {
  background: {
    default: '#FFFFFF',
    disabled: '#E8EAED',
    hovered: '#F8F8F8',
    pressed: '#F0F0F0',
  },
  action: {
    primary: '#0066B1',
    hovered: '#005696',
    pressed: '#003963',
    subdued: '#66686B',
    disabled: '#D6D6D6',
  },
  border: {
    default: '#949494',
    disabled: '#D6D6D6',
    hovered: '#005696',
    critical: {
      default: '#BA1B3B',
    },
  },
  icon: {
    default: '#FFFFFF',
    disabled: '#949494',
  },
  focus: {
    inner: '#306FEB',
    outer: '#306FEB',
  },
};

export const interactiveTokens = {
  interactive,
};
